export const MenuItems=[
{
    titles:'Home',
    url: '/Home',
    cName: 'nav-links',
},
{
    titles:'Services',
    url: 'Service',
    cName: 'nav-links',
},
{
    titles:'AboutUs',
    url: 'About',
    cName: 'nav-links',
},
{
    titles:'ContactUs',
    url: 'Contact',
    cName: 'nav-links',
},

]